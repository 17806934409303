





























































import {StoreInfo} from "@/request/merchant/StoreInfo";

let formData: any = {
    name: '',
    phone: '',
    province: '',
    city: '',
    county: '',
    address: '',
    desc: '',
    images: [],
    location: [104.082865, 30.523161]
};

export default {
    data() {
        let self: any =this;
        return {
            modal: StoreInfo,
            search: [
                {
                    type: 'Input',
                    label: '名称',
                    key: 'name',
                    width: '120px',
                    placeholder: '请填写名称'
                }
            ],
            formData: self.assignObject(formData),
            formRules: {
                name: {required: true, message: '请填写名称', trigger: 'blur'}
            },
            columns: [
                {
                    title: '名称',
                    key: 'name',
                    minWidth: 120
                },
                {
                    title: '联系电话',
                    key: 'phone',
                    minWidth: 140
                },
                {
                    title: '地址',
                    key: 'address',
                    minWidth: 200,
                    render: (h: any, p: any) => h('p', [p.row.province, p.row.city, p.row.county, p.row.address].join(' '))
                },
                {
                    title: '描述',
                    key: 'desc',
                    minWidth: 120
                }
            ]
        }
    },
    methods: {
        createNew() {
            let self: any = this;
            delete self.formData.id;
            self.formData = self.assignObject(formData);
            self.$refs['el-form'].resetFields();
        },
        edit(row: any) {
            let self: any = this;
            self.formData = row;
        },
        onMarkerChanged(info: any) {
            let self: any = this;
            self.formData.location = [info.lng, info.lat];
            let addressComponent: any = info.addressComponent;
            self.formData.province = addressComponent.province;
            self.formData.city = addressComponent.city;
            self.formData.county = addressComponent.district;
            self.formData.address = `${addressComponent.street} ${addressComponent.streetNumber}`
        },
        submit() {
            let self: any = this;
            let params = {...self.formData};
            self.$refs['el-form'].validate((valid: boolean) => {
                if (valid) {
                    self.$refs['el-table'].doSubmit(params).then(() => {

                    });
                }
            })
        },
        uploadSuccess(path: string) {
            let self: any = this;
            self.formData.images.push(path);
        }
    }
}
